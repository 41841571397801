.about {
  font-family: $font-primary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;

  &-title {
    text-transform: uppercase;
    color: $onedark-yellow;
  }

  &-bio {
    width: 75%;
    text-align: center;
    justify-content: center;
    color: $onedark-green;
  }
  &-table {
    &__title {
      display: flex;
      justify-content: left;
      align-items: center;
      color: $onedark-yellow;
      &__class {
        color: $onedark-purple;
      }
      &__content {
        color: $onedark-blue;
        margin: 0;
        padding-left: 2rem;
      }
    }
    &__interests {
      display: flex;
    }
  }
}
@media (max-width: 450px) {
  .about {
    margin-top: 15rem;
    .bio {
      width: 100%;
    }
  }
}
