body {
  background-color: #282c34;
  font-family: "Courier New", Courier, monospace;
}

.navbar-main {
  display: flex;
  justify-content: space-between;
}
.navbar-main__logo {
  margin-left: 1rem;
}
.navbar-main__home {
  cursor: pointer;
}
.navbar-main__name {
  margin: 0;
  color: #e06c75;
}
.navbar-main__name__function {
  color: #61afef;
}
.navbar-main__email {
  margin: 0;
  color: #abb2bf;
}
.navbar-main__navigators a {
  text-decoration: none;
  color: #e06c75;
  margin-right: 2rem;
}
.navbar-main__navigators a:hover {
  color: #56b6c2;
}

.title-main__name {
  margin-top: 15%;
  margin-bottom: 0%;
  font-size: 2.5rem;
  font-weight: 300;
  color: #abb2bf;
}
.title-main__end {
  color: #abb2bf;
}
.title-main__class {
  color: #e5c07b;
}
.title-main__attribute {
  color: #abb2bf;
  padding-left: 3rem;
}
.title-main__attribute__get a {
  text-decoration: none;
  color: #61afef;
}
.title-main__attribute__get__click {
  color: #e06c75;
}
.title-main__attribute__get__string {
  color: #98c379;
}

.about {
  font-family: "Courier New", Courier, monospace;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
}
.about-title {
  text-transform: uppercase;
  color: #e5c07b;
}
.about-bio {
  width: 75%;
  text-align: center;
  justify-content: center;
  color: #98c379;
}
.about-table__title {
  display: flex;
  justify-content: left;
  align-items: center;
  color: #e5c07b;
}
.about-table__title__class {
  color: #c678dd;
}
.about-table__title__content {
  color: #61afef;
  margin: 0;
  padding-left: 2rem;
}
.about-table__interests {
  display: flex;
}

@media (max-width: 450px) {
  .about {
    margin-top: 15rem;
  }
  .about .bio {
    width: 100%;
  }
}
.project {
  font-family: "Courier New", Courier, monospace;
}
.project-choices {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.project-choices__title {
  margin-right: 1rem;
}
.project-learning {
  padding-left: 2rem;
}
.project-interior {
  padding-left: 2rem;
}
.project-display {
  padding-left: 2rem;
}
.project-green {
  color: #98c379;
}
.project-yellow {
  color: #e5c07b;
}
.project-red {
  color: #e06c75;
}
.project-purple {
  color: #c678dd;
}
.project-blue {
  color: #61afef;
}
.project-gray {
  color: #abb2bf;
}
.project a {
  color: #98c379;
  text-decoration: none;
}

.contact {
  font-family: "Courier New", Courier, monospace;
  margin-top: 15%;
  display: flex;
  justify-content: left;
  flex-direction: column;
  align-items: left;
}
.contact-indent {
  padding-left: 2rem;
}
.contact-email {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: center;
}
.contact-title {
  color: #e5c07b;
}
.contact-bracket {
  color: #abb2bf;
}
.contact-green {
  color: #98c379;
}
.contact-yellow {
  color: #e5c07b;
}
.contact-red {
  color: #e06c75;
}
.contact-purple {
  color: #c678dd;
}
.contact-blue {
  color: #61afef;
}
.contact-gray {
  color: #abb2bf;
}
.contact a {
  color: #61afef;
  text-decoration: none;
}
.contact h3 {
  margin: 0.2rem;
}

