.navbar-main {
  display: flex;
  justify-content: space-between;
  &__logo {
    margin-left: 1rem;
  }
  &__home {
    cursor: pointer;
  }
  &__name {
    margin: 0;
    color: $onedark-red;
    &__function {
      color: $onedark-blue;
    }
  }
  &__email {
    margin: 0;
    color: $onedark-gray;
  }
  &__navigators {
    a {
      text-decoration: none;
      color: $onedark-red;
      margin-right: 2rem;
      &:hover {
        color: $onedark-aqua;
      }
    }
  }
}
