.contact {
  font-family: "Courier New", Courier, monospace;
  margin-top: 15%;
  display: flex;
  justify-content: left;
  flex-direction: column;
  align-items: left;
  &-indent {
    padding-left: 2rem;
  }

  &-email {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    align-items: center;
  }
  &-title {
    color: $onedark-yellow;
  }
  &-bracket {
    color: $onedark-gray;
  }
  &-green {
    color: $onedark-green;
  }
  &-yellow {
    color: $onedark-yellow;
  }
  &-red {
    color: $onedark-red;
  }
  &-purple {
    color: $onedark-purple;
  }
  &-blue {
    color: $onedark-blue;
  }
  &-gray {
    color: $onedark-gray;
  }
  a {
    color: $onedark-blue;
    text-decoration: none;
  }
  h3 {
    margin: 0.2rem;
  }
}
